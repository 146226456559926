<template>
    <router-view name="navBar" />
    <router-view v-slot="{Component, route}">
        <transition :name="route.meta.transition" mode="out-in">
            <component :is="Component" :key="route.path" />
        </transition>
    </router-view>
    <router-view name="footer" />
</template>

<script>
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";
import {notificationStore} from "@/store/notificationStore";

export default {
    name: 'Nachtaktivevents',
    setup(){
        const config = configStore()
        const user = userStore()
        const notifications = notificationStore()
        const auth = authStore()

        config.$subscribe((mutation, state) => {
            if(state.darkMode){
                localStorage.setItem('darkMode', JSON.stringify(1))
                document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
            }else{
                localStorage.setItem('darkMode', JSON.stringify(0))
                document.getElementsByTagName('html')[0].removeAttribute('class')
            }
        })
        window.projectURL = config.projectURL
        return {config, user, notifications, auth}
    },
    data () {
        return {
        }
    },
    watch: {
    },
    mounted () {
        const _this = this

        setInterval(()=>{
            _this.config.now = Date.now()
        },1000)

        this.notifications.loadNotification()
    },
    methods: {
    }
}
</script>

<style lang="css">
.ptr--icon,
.ptr--text {
    color: rgb(23 23 23 / 72%) !important;
}
body {
    height: 100vh !important;
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}
.spaceTop {
    margin-top: calc(constant(safe-area-inset-top) + 54px) !important;
    margin-top: calc(env(safe-area-inset-top) + 54px) !important;
}
.sidebar {
    top: calc(constant(safe-area-inset-top) + 54px) !important;
    top: calc(env(safe-area-inset-top) + 54px) !important;
    z-index: 1001 !important;
}
.notchFix {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
}
.blurPage{
    /* Any browser which supports CSS3 */
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 1000%;
    position: absolute;
    left: 0;
    top: 0;

    z-index: 99999;
    overflow:hidden;
}
.no-scroll{
    overflow: hidden;
}
.modal-mask {
    position: fixed;
    z-index: 1111;
    opacity: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    transition: all 0.3s ease;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
.sidebar-leave-active,
.sidebar-enter-active{
    transition: all 0.3s ease;
}

.sidebar-enter-from,
.sidebar-leave-to{
    left: -300px;
    opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    transition: all 0.2s ease-out;
}

.slide-right-enter-from,
.slide-left-leave-to {
    transform: translateX(-200px);
    opacity: 0;
}

.slide-left-enter-from,
.slide-right-leave-to {
    transform: translateX(200px);
    opacity: 0;
}
</style>

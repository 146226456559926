<template>
  <div class="paymentType t-right">
    <p class="color-black">
      Gesamt: {{ amount }}€
    </p>
    <div class="color-black" style="clear: both">
      <div v-if="auth.isAuth && user.credits > 0" class="flex credtCheckout" style="float: right;">
        <input id="credit" type="checkbox" v-model="useCredits">
        <label for="credit">Guthaben ({{ (user.credits / 100).toFixed(2) }}€) benutzen</label>
      </div>
    </div>
    <div class="color-black" v-if="useCredits" style="clear: both">
      -{{ (maxCredits / 100).toFixed(2) }}€ Guthaben
      <hr class="separator">
      Mollie: {{ (Math.round(100*(amount - maxCredits/100))/100).toFixed(2) }}€ <br>
      Guthaben danach: {{ (Math.round(user.credits - maxCredits) / 100).toFixed(2) }}€
    </div>
    <div class="color-black" v-else style="clear: both">
      Mollie: {{ amount }}€
    </div>
  </div>
</template>

<script>
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";

export default {
  name: "paymentType",
  props: {
    amount: {
      type: Number,
      required: true
    },
    checkCredits: {
      type: Boolean,
      default: false
    }
  },
  emits: ['total'],
  setup() {
    const auth = authStore()
    const user = userStore()

    return {auth, user}
  },
  data(){
    return{
      useCredits: this.checkCredits
    }
  },
  computed: {
    maxCredits(){
      return Math.min(this.user.credits, this.amount*100)
    }
  },
  watch: {
    useCredits(){
      this.$emit('total', this.useCredits ? this.maxCredits : 0)
    }
  },
  mounted() {
    this.$emit('total', this.useCredits ? this.maxCredits : 0)
  }
}
</script>

<style scoped>

  .separator{
    opacity: 0.1;
    margin: 16px 0;
  }

  .flex {
    display: flex;
  }

  .credtCheckout{
    column-gap: 6px;
    margin: 6px 0 0 0;
  }

  .paymentType{
    margin-top: 18px;
  }
</style>
<template>
  <main className="content-500" id="notifications">
    <div class="title color-black">
      <h1>Was Gibt's?</h1>
      <h2>Deine Benachrichtigungen</h2>
    </div>
    <section class="pad-16">
      <notification v-for="(noti, index) in notifications.messages" :notification="noti" :key="index" />
      <div v-if="!notifications.messages.length" class="t-center">
        <p class="color-grey">
          Keine Benachrichtigungen vorhanden.
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import notification from "@/components/notifications/notification";
import {notificationStore} from "@/store/notificationStore";


export default {
  name: "Notifications",
  title: "Notifications",
  components: {
    notification: notification,
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const notifications = notificationStore()
    const user = userStore()

    return {auth, config, notifications, user}
  },
  mounted() {
    this.config.loading = false
    this.notifications.loadNotification()
        .then(() => {
          this.notifications.clearNotification()
        })
        .catch(e => {})
  },
  unmounted() {
    this.notifications.loadNotification()
  },
  methods: {
  }
}
</script>

<style>
#notifications .title h1{
  margin-top: 16px;
  font-size: 2em;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
}
#notifications .title h2{
  font-size: 1.1em;
  font-weight: 200;
  color: rgba(0,0,0,0.8);
  text-align: center;
  margin-bottom: 32px;
}

</style>